import React from 'react';

const About = () => {
  return (
    <div>
      <h2>Product List</h2>
      {/* Add content for about us here */}
    </div>
  );
};

export default About;
